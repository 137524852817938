import { appWithTheme } from '@scorenco/components';
import {
  ApolloService,
  AmplifyService,
  SentryService,
  useTranslation,
} from '@scorenco/core';
import { AppLayout, AppPropsWithLayout, NextI18nService } from '@scorenco/next';
import 'iframe-resizer/js/iframeResizer.contentWindow';
import Head from 'next/head';

import { T_KEYS } from '../translations';

NextI18nService.init({
  i18n: {
    defaultLocale: 'fr',
    locales: ['fr'],
  },
  fallbackLng: ['fr'],
});

AmplifyService.init();

const App = (appProps: AppPropsWithLayout) => {
  const { t } = useTranslation();

  return (
    <SentryService>
      <ApolloService initialApolloState={appProps.pageProps.initialApolloState}>
        <AmplifyService>
          <Head>
            <title key="title">{t(T_KEYS.DEFAULT_HTML_TITLE)}</title>
          </Head>
          <AppLayout {...appProps} />
        </AmplifyService>
      </ApolloService>
    </SentryService>
  );
};

export default appWithTheme(NextI18nService.appEnhancer(App));
