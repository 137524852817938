import { Translations } from '@scorenco/components';

export const T_KEYS = {
  ...Translations.T_KEYS,

  DEFAULT_HTML_TITLE: 'DEFAULT_HTML_TITLE',

  GENERAL_RANKING: 'GENERAL_RANKING',
  BEST_ATTACK_RANKING: 'BEST_ATTACK_RANKING',
  BEST_DEFENSE_RANKING: 'BEST_DEFENSE_RANKING',

  DATE_FNS_FORMAT: 'DATE_FNS_FORMAT',

  TEAMS_FILTER: 'TEAMS_FILTER',
  TEAMS_FILTER_ALL: 'TEAMS_FILTER_ALL',

  TOURNAMENTS_FILTER: 'TOURNAMENTS_FILTER',
  TOURNAMENTS_EQUIPMENT: 'TOURNAMENTS_EQUIPMENT',

  CHALLENGES_FILTER: 'CHALLENGES_FILTER',

  MATCHS_ALL: 'MATCHS_ALL',

  TEAMS_ALL: 'TEAMS_ALL',

  MATCH_NOW: 'MATCH_NOW',
};

export type T_KEYS = typeof T_KEYS;
